<template>
  <b-card>
    <b-row>
      <b-col cols="9">
        <b-button v-ripple.400="'#1396EB'" v-b-modal.modal-primaryadd class="m-1 " variant="primary">
          Добавление
        </b-button>
      </b-col>
      <b-col cols="2">
        <b-form-select v-model="defaultLang" class="m-1" :options="langs" text-field="name" value-field="code"
          @change="getHistories" />
      </b-col>
    </b-row>
    <b-modal id="modal-primaryadd" ok-only ok-title="Добавить" modal-class="modal-primary" @ok="add"
      @hidden="resetModal" centered title="Добавление">
      <b-row>
        <b-col>
          <b-form-group>
            <label for="input-file" class="text-nowrap">Изображение:</label>
            <b-form-file id="input-file" v-model="history.file" placeholder="Добавьте изображение..."
              drop-placeholder="Drop file here..." />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Название" label-for="Title">
            <b-form-input id="Title" v-model="history.title" placeholder="Название" />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <label for="textarea-default" class="text-nowrap">Описание</label>
            <b-form-textarea id="textarea-default" v-model="history.descriptions" placeholder="Добавьте описание" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Дата" label-for="Date">
            <b-form-input id="Date" v-model="history.date" type="date" placeholder="Дата" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Язык" label-for="langs">
          <b-form-select v-model="history.lang" :options="langs" text-field="name" value-field="id" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-table :fields="tableColumns" responsive="sm" :items="histories">
      <template #cell(img)="data">
        <b-avatar v-if="data.item.img" :src="data.item.img"></b-avatar>
      </template>
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <b-button id="gradient-primaryy" v-b-modal.modal-primaryedit class="btn-icon mr-1" variant="gradient-primary"
            @click="edit(data.item)">
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button class="btn-icon" variant="gradient-danger" @click="confirmDelete(data.item)">
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
      </template>
    </b-table>
    <b-modal id="modal-primaryedit" ok-only ok-title="Редактировать" modal-class="modal-primary" @ok="handleEdit"
      @hidden="resetModal" centered title="Редактирование">
      <b-row>
        <b-col>
          <b-form-group>
            <label for="input-file" class="text-nowrap">Изображение:</label>
            <b-form-file id="input-file" v-model="history.file" placeholder="Добавьте изображение..."
              drop-placeholder="Drop file here..." />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Название" label-for="Title">
            <b-form-input id="Title" v-model="history.title" placeholder="Название" />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <label for="textarea-default" class="text-nowrap">Описание</label>
            <b-form-textarea id="textarea-default" v-model="history.descriptions" placeholder="Добавьте описание" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Дата" label-for="Date">
            <b-form-input id="Date" v-model="history.date" type="date" placeholder="Дата" autocomplete="off" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Язык" label-for="langs">
          <b-form-select v-model="history.lang" :options="langs" text-field="name" value-field="id" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BAvatar, BFormSelect, BFormFile, BFormTextarea, BFormInput, BRow, BCol, BFormGroup, BModal, VBModal, BButton, BCard, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BAvatar,
    ToastificationContent,
    BFormSelect,
    axios,
    BFormFile,
    BFormTextarea,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BModal,
    VBModal,
    BCard,
    BButton,
    BTable,

  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },

  data() {
    return {
      IMG_URL: $themeConfig.app.IMG_URL,
      defaultLang: 'ru',
      langs: [],
      histories: [],
      history: {
        title: '',
        file: [],
        descriptions: '',
        date: '',
        lang: '',
      },
      tableColumns: [
        {
          key: 'id',
          label: '#',
          sortable: true,
        },
        {
          key: 'title',
          label: 'Название',
          sortable: true,
        },
        {
          key: 'img',
          label: 'Изображение',
          sortable: true,
        },
        {
          key: 'description',
          label: 'Описание',
          sortable: true,
        },
        {
          key: 'date',
          label: 'Дата',
          sortable: true,
        },

        {
          key: 'actions',
          label: 'Действия',
        }],
    }
  },
  mounted() {
    this.getLangs()
    this.getHistories()
  },
  methods: {
    getLangs() {
      axios.get(`${$themeConfig.app.API}langs`)
        .then(res => {
          this.langs = res.data.data
        })
        .catch(er => {
          console.log(er)
        })
    },
    getDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()

      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`

      return [year, month, day].join('-')
    },
    getHistories() {
      const getHistories = {
        method: 'get',
        url: `${$themeConfig.app.API}history`,
        headers: {
          'Accept-Language': this.defaultLang,
        },
      }
      axios(getHistories)
        .then(res => {
          this.histories = res.data.data
        })
        .catch(er => {
          console.log(er)
        })
    },
    async add() {
      const myFormData = new FormData()
      myFormData.append('title', this.history.title)
      myFormData.append('img', this.history.file)
      myFormData.append('description', this.history.descriptions)
      myFormData.append('lang_id', this.history.lang)
      myFormData.append('date', this.history.date)

      await axios({
        method: 'post',
        url: `${$themeConfig.app.API}history`,
        data: myFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          this.addStatus = true
          this.getHistories()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              text: 'Запись успешно добавлен',
              icon: 'CheckSquareIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          this.addStatus = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка',
              text: e.message,
              icon: 'CheckSquareIcon',
              variant: 'danger',
            },
          })
        })
    },
    resetModal() {
      this.history.title = ''
      this.history.id = ''
      this.history.lang = ''
      this.history.file = ''
      this.history.date = ''
      this.history.descriptions = ''
    },
    async handleEdit() {
      const myFormData = new FormData()
      myFormData.append('title', this.history.title)
      myFormData.append('img', this.history.file)
      myFormData.append('description', this.history.descriptions)
      myFormData.append('lang_id', this.history.lang)
      myFormData.append('date', this.history.date)
      myFormData.append('_method', 'PUT')
      if (this.history.id !== '') {
        await axios({
          method: 'post',
          url: `${$themeConfig.app.API}/history/${this.history.id}`,
          data: myFormData,
          config: {},
        })
          // (`https://back.khojaobigarm.tj/api/news/${this.news.id}`, myFormData)
          .then(() => {
            this.getHistories()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Успешно',
                text: 'Данные были изменены',
                icon: 'CheckSquareIcon',
                variant: 'success',
              },
            })
          })
          .catch(e => {
            console.log(e)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ошибка',
                text: e.message,
                icon: 'CheckSquareIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    edit(data) {
      this.history.date = this.getDate(data.date)
      this.history.title = data.title
      this.history.id = data.id
      this.history.lang = data.lang.id
      this.history.file = data.img
      this.history.descriptions = data.description

    },
    confirmDelete(data) {
      this.$bvModal
        .msgBoxConfirm('После удаления данные восстановить нельзя будет!', {
          title: 'Вы уверены что хотите удалить?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Удалить',
          cancelTitle: 'Отменить',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            axios.delete(`${$themeConfig.app.API}history/${data.id}`)
              .then(() => {
                this.getHistories()
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Успешно',
                    text: 'Данные были удалены',
                    icon: 'CheckSquareIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(e => {
                console.log(e)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Ошибка',
                    text: e.message,
                    icon: 'CheckSquareIcon',
                    variant: 'dander',
                  },
                })
              })
          }
        })
    },
  },
}
</script>
